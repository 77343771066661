<template>
    <div style="width: 1000px;min-height:100vh;display: flex;justify-content: center;align-items: center;margin: 0 auto;">
         <div style="margin-top: 120px;">
             <div style="width: 1000px;min-height:1200px;background: #FFFFFF">
                 <div style="clear: both;display: flex;align-items: center;padding-top: 60px">
                     <div style="font-size: 25px;color:#444444;font-weight:bold;display: flex;justify-content: center;flex: 1;">出差申请单</div>
                 </div>
                 <div style="clear: both;padding-top: 30px;">
                     <div style="width: 920px;height: 10px;background: #FF9800;border-radius: 20px 20px 0px 0px;margin: 0 auto;"></div>
                     <div class="gradient-border" style="width: 916px;min-height: 900px;clear: both;margin: 0 auto;box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.16);">
                         <teble style="width: 100%;font-size: 14px;">
                             <tr class="typeface">
                                 <th style="width: 100px;height: 20px;padding: 23px 42.5px;font-size: 17px; color: #444444;border-left:1px solid #808080;border-right:1px solid #CFCFCF;font-weight: bold;border-bottom:1px solid #CFCFCF;border-left: none">申报部门</th>
                                 <td style="width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">{{content.fd_1702965716981}}</td>
                                 <th style=" width: 100px;height: 20px;padding: 23px 41px;font-size: 17px; color: #444444;font-weight: bold;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">填报日期</th>
                                 <td style="width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #808080;border-right: none">{{content.fd_1702973207538}}</td>
                             </tr>
                         </teble>
                         <table border="1" style="width: 100%;height: 920px;overflow: hidden;font-size: 14px;border: none">
                             <tr class="typeface">
                                 <th class="title">出差日期</th>
                                 <td class="content" ><div style="float: left;" v-for="(item,index) in content.fd_1703498556296" :key="index" :style="index == 1?'margin-left: 10px':''">{{item}} <span style="padding: 0 10px;" v-if="index !== content.fd_1703498556296.length - 1">至</span></div></td>
                             </tr>
                             <tr class="typeface">
                                 <th class="title" style="width: 120px">出差人员名单</th>
                                 <td class="content">{{content.fd_1677729356192}}</td>
                             </tr>
                             <tr class="typeface">
                                 <th class="title">出差事由</th>
                                 <td class="content">{{content.fd_1702973515542}}</td>
                             </tr>
                             <tr class="typeface">
                                 <th class="title">出差地点</th>
                                 <td class="content">{{content.fd_1702973468326}}</td>
                             </tr>
                             <tr class="typeface">
                                 <th class="title">所属项目</th>
                                 <td class="content">{{content.fd_1702982787620}}</td>
                             </tr>
                             <tr class="typeface">
                                 <th style="width: 100px;height: 100px;padding: 23px 26px;font-size: 15px;color: #444444;border-bottom:1px solid #CFCFCF;font-weight: bold;border-right:1px solid #CFCFCF;border-left: none;border-top: none">往返交通</th>
                                 <td style="font-size: 15px;height: 100px;color: #444444;width: 260px;border-bottom:1px solid #CFCFCF;border-top: none;border-left: none;border-right: none;padding: 0 10px;">
                                      <div style="font-size: 15px;color: #444444;font-weight: bold;">1.去</div>
                                      <div style="margin-top: 10px;">
                                          <el-radio-group v-model="radiogo" :disabled="radiogo == content.fd_1702965880225">
                                              <el-radio :label="1">飞机</el-radio>
                                              <el-radio :label="2">动车</el-radio>
                                              <el-radio :label="3">火车</el-radio>
                                              <el-radio :label="4">汽车</el-radio>
                                              <el-radio :label="5">轮船</el-radio>
                                              <el-radio :label="6">其它</el-radio>
                                          </el-radio-group>
                                      </div>
                                     <div style="font-size: 15px;color: #444444;font-weight: bold;margin-top: 20px;">2.回</div>
                                     <div style="margin-top: 10px;">
                                         <el-radio-group v-model="radiocircle" :disabled="radiocircle == content.fd_1702966153115">
                                             <el-radio :label="1">飞机</el-radio>
                                             <el-radio :label="2">动车</el-radio>
                                             <el-radio :label="3">火车</el-radio>
                                             <el-radio :label="4">汽车</el-radio>
                                             <el-radio :label="5">轮船</el-radio>
                                             <el-radio :label="6">其它</el-radio>
                                         </el-radio-group>
                                     </div>
                                 </td>
                             </tr>
                             <tr class="typeface">
                                 <th class="title">经办人</th>
                                 <td class="content">{{content.fd_1702972923359}}</td>
                             </tr>
                             <tr class="typeface">
                                 <th class="title" style="border-bottom: none">备注</th>
                                 <td class="content" style="border-bottom: none">{{content.fd_1702966286713}}</td>
                             </tr>
                         </table>
                     </div>
                 </div>
             </div>
         </div>
    </div>
</template>
<script>
export default {
    components: {

    },

    computed: {

    },
    data () {
        return {
            radiogo: 1,
            radiocircle:1,
            content:{},
        }
    },
    mounted:function(){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.getRoomContent()
    },
    methods:{
        // 获取内容
        getRoomContent() {
            this.newApi.getRoomContentInfo({id: this.$route.params.id}).then(res =>{
                this.content = res.data.content;
                this.radiogo = res.data.content.fd_1702965880225
                this.radiocircle = res.data.content.fd_1702966153115
            })
        },

    }
}
</script>
<style scoped>
.spacing {
    font-size: 18px;
    color: #444444;
    font-weight: bold;
    margin-left: 50px;
    font-family: "SimHei";
}
.price {
    font-size: 18px;
    color: #444444;
}
.title {
    width: 100px;
    height: 20px;
    padding: 23px 18px;
    font-size: 17px;
    color: #444444;
    border-bottom:1px solid #CFCFCF;
    font-weight: bold;
    border-right:1px solid #CFCFCF;
    border-left: none;
    border-top: none;
}
.content {
    font-size: 17px;
    height: 20px;
    color: #444444;
    width: 80%;
    border-bottom:1px solid #CFCFCF;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 10px;
}
 .typeface {
     font-family:'SimHei';
     width: 100%
 }
.gradient-border {
    border-right: 2px solid #ddd;
    border-left: 2px solid #ddd;
    border-image: linear-gradient(rgb(255, 152, 0), #FFFFFF) 30 30;
}
/deep/ .el-radio__inner {
    width: 17px;
    height: 17px;
}
/deep/ .el-radio__inner::after {
    width: 7px;
    height: 7px;
    left: 51%;
    top: 54%;
}
/deep/ .el-radio__label {
    font-size: 15px;
}
</style>